import { deprecatedMakeStyles } from '@vakantiesnl/components/src/styles/themes/themewrapper';

const useStyles = deprecatedMakeStyles<void, 'large' | 'extraLarge' | 'small'>()((theme, _, classes) => ({
	container: {
		boxSizing: 'border-box',
		textDecorationLine: 'none',
		display: 'inline-block',
		minHeight: '250px',
		minWidth: '218px',
		width: '290px',
		height: '320px',

		[theme.breakpoints.down('md')]: {
			width: '218px',
			height: '250px',
		},

		[`&.${classes.large}`]: {
			width: '100%',
		},

		[`&.${classes.extraLarge}`]: {
			width: '100%',
			height: '200px',

			[theme.breakpoints.up('sm')]: {
				width: '308px',
				height: '396px',
			},

			[theme.breakpoints.up('lg')]: {
				width: '394px',
				height: '480px',
			},
		},

		'& > div': {
			width: '100%',
			height: '100%',
		},
	},
	wrapper: {
		position: 'relative',
		width: '100%',
		height: '100%',

		'& img': {
			display: 'block',
			objectFit: 'cover',
		},
	},
	buttonContainer: {
		position: 'absolute',
		right: '10px',
		bottom: '10px',
		left: '10px',
		display: 'flex',

		[`&.${classes.large}`]: {
			justifyContent: 'flex-end',
		},

		'& button': {
			fontSize: '0.825rem',
			display: 'flex',

			[`&.${classes.small}`]: {
				flex: '1 1 auto',
			},
			[`&.${classes.large}`]: {
				width: '384px',
				justifyContent: 'space-between',
			},
		},
	},
	priceWrapper: {
		position: 'absolute',
		right: theme.spacing(1.5),
		top: theme.spacing(1.5),
		width: '100px',
		height: '100px',

		[theme.breakpoints.up('sm')]: {
			right: theme.spacing(3),
			top: theme.spacing(3),
			width: '120px',
			height: '120px',
		},

		'& > div': {
			textAlign: 'right',
		},
	},
	priceBackground: {
		fill: theme.palette.common.white,
		width: '100%',
		height: '100%',
	},
	price: {
		position: 'absolute',
		left: '50%',
		top: '50%',
		transform: 'translate(-50%, -50%)',
		width: 'auto',
		maxWidth: '100px',

		'& > div > div': {
			minWidth: '55px',
		},
	},
	title: {
		position: 'absolute',
		left: theme.spacing(3),
		bottom: theme.spacing(2.5),
		display: 'block',
		color: theme.palette.common.white,
		fontWeight: theme.typography.fontWeightBold as number,
		fontSize: '24px',
		filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',
		lineHeight: 1.2,

		[theme.breakpoints.up('sm')]: {
			fontSize: '28px',
		},

		[theme.breakpoints.up('lg')]: {
			fontSize: '32px',
		},
	},
	large: {},
	small: {},
	extraLarge: {},
}));

export default useStyles;
